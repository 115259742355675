@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin anchorAlign($primary, $secondary, $pad) {
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    @if $primary == top {
        top: calc(anchor(bottom) + $pad);
    } @else if $primary == left {
        left: calc(anchor(right) + $pad);
    } @else if $primary == right {
        right: calc(anchor(left) + $pad);
    } @else if $primary == bottom {
        bottom: calc(anchor(top) + $pad);
    }
    @if $secondary == top {
        top: anchor(top);
    } @else if $secondary == left {
        left: anchor(left);
    } @else if $secondary == right {
        right: anchor(right);
    } @else if $secondary == bottom {
        bottom: anchor(bottom);
    }
    transform-origin: $primary $secondary;
}

@position-try --popover-aligned-bottom-right {
    @include anchorAlign(bottom, right, 2px);
    min-inline-size: anchor-size(self-inline);
}
@position-try --popover-aligned-bottom-left {
    @include anchorAlign(bottom, left, 2px);
    min-inline-size: anchor-size(self-inline);
}
@position-try --popover-aligned-left-top {
    @include anchorAlign(left, top, 2px);
    min-inline-size: fit-content;
}
@position-try --popover-aligned-right-top {
    @include anchorAlign(right, top, 2px);
    min-inline-size: fit-content;
}

@position-try --anchor-aligned-bottom-left {
    @include anchorAlign(bottom, left, 0.25em);
}
@position-try --anchor-aligned-bottom-right {
    @include anchorAlign(bottom, right, 0.25em);
}



@position-try --anchor-aligned-top-left {
    @include anchorAlign(top, left, 0.25em);
}


@position-try --anchor-aligned-top-right {
    @include anchorAlign(top, right, 0.25em);
}

@mixin useMainColor($color: "slate") {
    @include themeRoot();
    --main-50: var(--#{#{$color}}-50);
    --main-100: var(--#{#{$color}}-100);
    --main-200: var(--#{#{$color}}-200);
    --main-300: var(--#{#{$color}}-300);
    --main-400: var(--#{#{$color}}-400);
    --main-500: var(--#{#{$color}}-500);
    --main-600: var(--#{#{$color}}-600);
    --main-700: var(--#{#{$color}}-700);
    --main-800: var(--#{#{$color}}-800);
    --main-900: var(--#{#{$color}}-900);
    --main-950: var(--#{#{$color}}-950);
}

@mixin useAccentColor($color: "blue") {
    @include themeRoot();
    --accentColor-50: var(--#{#{$color}}-50);
    --accentColor-100: var(--#{#{$color}}-100);
    --accentColor-200: var(--#{#{$color}}-200);
    --accentColor-300: var(--#{#{$color}}-300);
    --accentColor-400: var(--#{#{$color}}-400);
    --accentColor-500: var(--#{#{$color}}-500);
    --accentColor-600: var(--#{#{$color}}-600);
    --accentColor-700: var(--#{#{$color}}-700);
    --accentColor-800: var(--#{#{$color}}-800);
    --accentColor-900: var(--#{#{$color}}-900);
    --accentColor-950: var(--#{#{$color}}-950);
}

@mixin themeRoot() {
    --accent: var(--accent-500);
    accent-color: var(--accent);

    
    --text: light-dark(var(--main-950), var(--main-50));
    --background: light-dark(var(--main-50), var(--main-950));

    --background-50: light-dark(var(--main-50), var(--main-950));
    --background-100: light-dark(var(--main-100), var(--main-900));
    --background-200: light-dark(var(--main-200), var(--main-800));
    --background-300: light-dark(var(--main-300), var(--main-700));
    --background-400: light-dark(var(--main-400), var(--main-600));
    --background-500: var(--main-500);
    --background-600: light-dark(var(--main-600), var(--main-400));
    --background-700: light-dark(var(--main-700), var(--main-300));
    --background-800: light-dark(var(--main-800), var(--main-200));
    --background-900: light-dark(var(--main-900), var(--main-100));
    --background-950: light-dark(var(--main-950), var(--main-50));

    --accent-50: light-dark(var(--accentColor-50), var(--accentColor-950));
    --accent-100: light-dark(var(--accentColor-100), var(--accentColor-900));
    --accent-200: light-dark(var(--accentColor-200), var(--accentColor-800));
    --accent-300: light-dark(var(--accentColor-300), var(--accentColor-700));
    --accent-400: light-dark(var(--accentColor-400), var(--accentColor-600));
    --accent-500: var(--accentColor-500);
    --accent-600: light-dark(var(--accentColor-600), var(--accentColor-400));
    --accent-700: light-dark(var(--accentColor-700), var(--accentColor-300));
    --accent-800: light-dark(var(--accentColor-800), var(--accentColor-200));
    --accent-900: light-dark(var(--accentColor-900), var(--accentColor-100));
    --accent-950: light-dark(var(--accentColor-950), var(--accentColor-50));
}



@mixin useTheme($theme: light) {
    @media not print {
        @include themeRoot();
        color-scheme: $theme;
    }
    @media print {
        @include useMainColor("neutral");
        color-scheme: light;
    }
}
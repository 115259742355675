@use "./theme/theme.scss";
@use "./icon/icons.scss";
@use "./components/error.scss";
@use "./components/loading.scss";
@use "./components/textbox.scss";
@use "./components/grid.scss";
@use "./components/titlebar.scss";
@use "./components/preloader.scss";
@use "./components/popover.scss";
@import "icon/icon";
@import url("../assets/font/fonts.css");
body {
    overflow: hidden;
    margin: 0;
}

[inert] {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

.grow {
    flex-grow: 1;
}

[data-invisible="true"] {
    display: none !important;
}

button,
a {
    cursor: pointer;
}

textarea {
    resize: vertical;
    min-height: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: anywhere;
}

.upperSubtitle {
    text-transform: uppercase;
    color: var(--background-700);
    font-weight: 600;
    font-size: 100%;
}

a:not([class]),
.link {
    text-decoration: none;
    color: var(--accent);
    text-shadow: 0 0 0.3em var(--accent-200);
    transition: 0.25s text-shadow, 0.25s color;
    &:hover {
        text-decoration: underline;
        text-shadow: 0 0 0.5em var(--accent-400);
        color: var(--accent-600);
    }
}

div[id="__parcel__error__overlay__"],
#testerroroverlay {
    >div {
        background: var(--background-50) !important;
        opacity: 1 !important;
        box-sizing: border-box;
        >div {
            margin-top: 125px;
            a {
                text-shadow: none;
            }
            padding: 1em;
            background: #0a0a0a;
            box-shadow: 0 0 0.5em #0a0a0a88;
            color: #fafafa;
            border-radius: 4px;
            >div:first-child {
                &::before {
                    content: "ERROR DETAILS";
                    display: block;
                    font-family: var(--font-family) !important;
                    color: #a3a3a3;
                }
                margin-top: 0 !important;
            }
            &::after {
                @include icon("error");
                font-size: 64px;
                position: fixed;
                top: 24px;
                left: 0;
                width: 100%;
                text-align: center;
                color: var(--text) !important;
                font-weight: 700 !important;
            }
            &::before {
                font-family: var(--font-family) !important;
                content: "Sorry! This application is currently out of order.";
                top: 90px;
                font-size: 1.5em;
            }
            &::before,
            &::after {
                position: fixed;
                left: 0;
                width: 100%;
                text-align: center;
                font-weight: 700 !important;
                color: var(--text) !important;
            }
        }
    }
}

small {
    font-size: 0.75em;
    font-style: italic;
    color: var(--background-600);
}

.fadeIn {
    animation: fade-in 0.125s ease-in-out;
}

@keyframes fade-in {
    from {
        opacity: 0;
        filter: saturate(0.2);
    }
    to {
        opacity: 1;
        filter: saturate(1);
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
        filter: saturate(1);
    }
    to {
        opacity: 0;
        filter: saturate(0.2);
    }
}

@keyframes slide-from-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-from-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-to-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes slide-to-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes scale-in {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scale-out {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 1s infinite;
    text-align: center;
}

progress {
    flex-grow: 1;
}

.width-100 {
    width: 100%;
}

@media print {
    button,
    input[type="file"],
    textarea {
        display: none !important;
    }
    * {
        box-shadow: none !important;
    }
}

[data-unsaved="true"] {
    --unsaved-glow: light-dark(var(--red-200), var(--red-900));
    animation: 1s ease-in-out infinite alternate unsaved;
    @keyframes unsaved {
        0% {}
        50% {
            background: var(--unsaved-glow);
            box-shadow: 0 0 2.5em var(--unsaved-glow);
            transform: scale(1.05);
        }
        100% {}
    }
}

.clampedText {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@import "../theme/useMainColor";
@import "../icon/icon";
@import "../util";
.error {
    @include useAccentColor($color: "red");
    color: var(--accentColor-700);
    &::before {
        @include icon("error");
        font-size: 150%;
        vertical-align: text-bottom;
    }
}
.cError {
    @include flexCenter;
    > .symbols {
        font-size: 48px;
    }
    flex-direction: column;
    text-align: left;
    max-width: max-content;
    margin-inline: auto;
    margin-block: auto;
    * {
        margin: 0.3em;
        width: 100%;
    }
    i {
        margin-bottom: 0;
    }
}
@import "../util";

@mixin popover-hidden-state {
    opacity: 0;
    transform: scale(0.75);
    &::backdrop {
        opacity: 0;
    }
}

$popover-transition-duration: 0.125s;
@mixin popover-transitions {
    transition: 
            transform $popover-transition-duration ease-out,
            opacity $popover-transition-duration ease-out, 
            overlay $popover-transition-duration ease-out allow-discrete, 
            display $popover-transition-duration ease-out allow-discrete;
    &::backdrop {
        transition: opacity $popover-transition-duration ease-out;
    }
}

.cPopover {
    margin: 0;
    padding: 0;
    overflow: visible;
    // position-area: end span-start;
    
    box-sizing: border-box;

    
    border: none;
    background: none;
    min-inline-size: anchor-size(self-inline);
    &.align-left {
        @include anchorAlign(top, left, 2px);
        position-try-fallbacks: --popover-aligned-bottom-left, --popover-aligned-left-top, --popover-aligned-right-top;

    }
    &.align-right {
        @include anchorAlign(top, right, 2px);
        position-try-fallbacks: --popover-aligned-bottom-right, --popover-aligned-right-top, --popover-aligned-left-top;
    }


    @include popover-hidden-state;
    @include popover-transitions;

    &:not(:popover-open) {
        display: none;
    }
    &::backdrop {
        background: black;
    }
    &:popover-open {
        opacity: 1;
        transform: scale(1);
        &::backdrop {
            // opacity: 0.05;
        }
    }
   
}
@starting-style {
    .cPopover {
        @include popover-transitions;
        &:popover-open {
           @include popover-hidden-state;
        }
    }
}
// @keyframes popover-fade-in {
//     from {
//         opacity: 0;
//     }
//     to {
//         opacity: 0.15;
//     }
// }
.shimmering,
[data-busy="true"] {
    opacity: 0.7;
    filter: brightness(1.2) blur(2px);
    pointer-events: none !important;
    mask-mode: alpha;
    mask-image: linear-gradient(-45deg, black 0%, black 33%, transparent 50%, black 66%, black 100%);
    mask-size: 300% 300%;
    mask-repeat: no-repeat;
    animation: shimmer 1.5s ease-in-out infinite 0.625s;
}

@keyframes shimmer {
    0% {
        mask-position: 0 0;
    }
    100% {
        mask-position: 100% 100%;
    }
}

.shimmerLarge {
    pointer-events: none !important;
    opacity: 0.7;
    filter: brightness(1.2) blur(8px);
}

.shimmerInvert {
    opacity: 0.7;
    filter: brightness(1.2) blur(2px);
    pointer-events: none;
    background-image: linear-gradient(-45deg, transparent 0%, transparent 33%, #0004 50%, transparent 66%, transparent 100%);
    background-size: 300% 300%;
    background-repeat: no-repeat;
    animation: shimmerBG 1.5s ease-in-out infinite 0.625s;
}

@keyframes shimmerBG {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 100%;
    }
}
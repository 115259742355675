.titlebar {
            
    background: var(--background-100);
    color: var(--text);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    padding: 1em;
    padding-block: 0.75em;
    display: grid;
    grid-template-columns: auto fit-content(100%);
    align-items: center;
    

    > .text {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        grid-area: 1 / 1 / 2 / 2;
        

        > h2 {
            color: var(--background-600);
            font-weight: 500;
            font-size: 18px;

        }
        > h1 {
            font-weight: 600;
            font-size: 24px;
        }
    }
    > .buttons {

        grid-area: 1 / 2 / 2 / 3;
        gap: 1em;
        display: flex;

        > button {
            padding: 0;
            margin: 0;
            font-size: 36px;
            line-height: 0;
            background: none;
            border: none;
            color: var(--text);
            text-shadow: 0 0 0px var(--background-400);
            transition: 0.25s text-shadow, 0.25s font-weight;
            &:hover {
                text-shadow: 0 0 8px var(--background-400);
                font-weight: 900;
            }
        }
    }

    &[data-thin="true"] {
        > .text {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h1 {
                font-size: 22px; 
            }
            h2 {
                font-size: 16px;
            }
        }
        > .buttons > button {
            font-size: 24px;
        }
    }
}
@import "../util";
@import "../theme/useMainColor";
.preload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.initApp {
        display: grid;
        grid-template-columns: 300px 1fr;
        .preloadSidebar {
            background-color: var(--main-900);
            background-image: linear-gradient(to left, var(--main-950), var(--main-900));
            background-size: 8px 100%;
            background-repeat: repeat-y;
            background-position: top right;
        }
        .preloadTaskState {
            > h1, > p {
                display: none;
                font-size: 0.5em;
            }
        }
        
    }
}
.preloadTaskState {
    @include flexCenter;
    flex-direction: column;
    text-align: left;
    max-width: 500px;
    margin-inline: auto;
    margin-block: auto;
    > * {
        margin: 0.1em;
        width: 100%;
    }
    i {
        margin-bottom: 0;
        font-size: 150%;
        vertical-align: middle;
    }
    tr {
        color: var(--main-800);
        font-family: var(--font-family);
        &.status-done {
            @include useMainColor("green");
            font-weight: 500;
        }
        &.status-error {
            @include useMainColor("red");
            font-weight: 600;
        }
        &.status-current {
            font-weight: 800; 
        }
        &.status-pending {
            font-weight: 400;
        }
        * {
            font-weight: inherit;
        }
        
    }
    pre {
        white-space: break-spaces;
    }
}
@use "./swatch.scss";

@import "useMainColor";
:root {
  @include useMainColor("slate");
  @include useAccentColor("sky");
  @include useTheme(light); 
}
:root {  
  --font-family: "Inter", sans-serif;
  color: var(--text);
  font-family: var(--font-family);
  font-feature-settings: 'liga' 1, 'calt' 1;
  @media not print {
    background-color: var(--background);
  }
}
input,button,textarea,select {
  font-family: var(--font-family);
  color: var(--text);
}

.light { @include useTheme(light); }
.dark { @include useTheme(dark); }
.auto { @include useTheme(light dark); }

.m-slate { @include useMainColor("slate"); }
.m-neutral { @include useMainColor("neutral"); }
.m-gray { @include useMainColor("gray"); }
.m-zinc { @include useMainColor("zinc"); }
.m-stone { @include useMainColor("stone"); }
.m-red { @include useMainColor("red"); }
.m-orange { @include useMainColor("orange"); }
.m-amber { @include useMainColor("amber"); }
.m-yellow { @include useMainColor("yellow"); }
.m-lime { @include useMainColor("lime"); }
.m-green { @include useMainColor("green"); }
.m-emerald { @include useMainColor("emerald"); }
.m-teal { @include useMainColor("teal"); }
.m-cyan { @include useMainColor("cyan"); }
.m-sky { @include useMainColor("sky"); }
.m-blue { @include useMainColor("blue"); }
.m-indigo { @include useMainColor("indigo"); }
.m-violet { @include useMainColor("violet"); }
.m-purple { @include useMainColor("purple"); }
.m-fuchsia { @include useMainColor("fuchsia"); }
.m-pink { @include useMainColor("pink"); }
.m-rose { @include useMainColor("rose"); }

.slate { @include useAccentColor("slate"); }
.neutral { @include useAccentColor("neutral"); }
.gray { @include useAccentColor("gray"); }
.zinc { @include useAccentColor("zinc"); }
.stone { @include useAccentColor("stone"); }
.red { @include useAccentColor("red"); }
.orange { @include useAccentColor("orange"); }
.amber { @include useAccentColor("amber"); }
.yellow { @include useAccentColor("yellow"); }
.lime { @include useAccentColor("lime"); }
.green { @include useAccentColor("green"); }
.emerald { @include useAccentColor("emerald"); }
.teal { @include useAccentColor("teal"); }
.cyan { @include useAccentColor("cyan"); }
.sky { @include useAccentColor("sky"); }
.blue { @include useAccentColor("blue"); }
.indigo { @include useAccentColor("indigo"); }
.violet { @include useAccentColor("violet"); }
.purple { @include useAccentColor("purple"); }
.fuchsia { @include useAccentColor("fuchsia"); }
.pink { @include useAccentColor("pink"); }
.rose { @include useAccentColor("rose"); }

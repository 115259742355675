.controlGroup {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    grid-auto-columns: minmax(250px, auto);
    column-gap: 1em;
    margin-block: 1em;
    padding-right: 0.25em;
    > h1, > h2, > h3, > h4, > h5, > h6, > p, > pre {
        grid-row: 1;
    }
    > * {
        grid-row: 2;
    }
    &.center {
        align-items: center;

    }
}


@import "../theme/useMainColor";
@import "../icon/icon";
.inputEntry {
    @include useMainColor("neutral");
    background: var(--background-50);
    color: var(--background-700);
    font-weight: 400;
    border: 1px solid var(--background-300);
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    margin-block: 2px;
    transition: 0.25s background, 0.25s color, 0.25s border, 0.25s font-weight, 0.25s box-shadow;
    box-sizing: border-box;
    &:focus,
    &.highlighted {
        outline: none;
        background-color: var(--accent-50);
        &:not(.large) {
            color: var(--background-900);
            border-size: 3px;
            font-weight: 500;
        }
        border-color: var(--accent-400);
    }
    &:last-of-type {
        margin-bottom: 0.5em;
    }
    &.transparent {
        background: none;
        box-shadow: none;
        &::webkit-resizer {
            display: none;
        }
    }
    &[data-invalid="true"] {
        @include useAccentColor("red");
        border-color: var(--accent-400);
        color: var(--accent-800);
    }
}

label:not([class]),
.cLabel {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--background-700);
    padding-left: 4px;
}

.searchBox {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 30px;
    input {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        width: 100%;
        transition: 0.25s background, 0.25s color, 0.25s border, 0.25s font-weight, 0.25s border-radius;
        border-radius: 16px;
        padding-left: 2em;
        margin-top: 0;
        margin-bottom: 0 !important;
    }
    &::before {
        @include icon("search");
        color: var(--background-600);
        transform: rotateY(180deg);
        grid-area: 1 / 1 / 2 / 2;
        z-index: 2;
        font-size: 1.25em;
        width: 1em;
        top: 0;
        align-self: center;
        padding-inline: 6px;
    }
}